@import-normalize;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;
  height: 100%;
  font-family: 'Montserrat',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(16, 0, 48, 1);
  background: radial-gradient(circle at top right, rgb(0, 253, 198) 0%, rgba(16, 0, 48, 1) 75%);
  color: white;
  }

  .TypoHeader {
    font-family: 'Exo', 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
