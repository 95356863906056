$color: #47ffb6;

.NeonButton {
    background-color: transparent;
    outline: 0;
    border: 0;
    position: relative;
    display: inline-block;
    padding: 25px 30px;
    margin: 40px 0;
    color: $color;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 3px;
    font-weight: bolder;
    overflow: hidden;

    &:hover {
        background: $color;
        padding: 28px 35px;
        color: #000000;
        box-shadow: 0 0 5px $color,
            0 0 25px $color,
            0 0 50px $color,
            0 0 200px $color;
        -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
    }

    & span {
        position: absolute;
        display: block;

        &:nth-child(1) {
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg, transparent, $color);
            animation: NeonButtonAnimate1 2s linear infinite;
        }

        &:nth-child(2) {
            top: -100%;
            right: 0;
            width: 2px;
            height: 100%;
            background: linear-gradient(180deg, transparent, $color);
            animation: NeonButtonAnimate2 2s linear infinite;
            animation-delay: 0.50s;
        }

        &:nth-child(3) {
            bottom: 0;
            right: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(270deg, transparent, $color);
            animation: NeonButtonAnimate3 2s linear infinite;
            animation-delay: 1s;
        }

        &:nth-child(4) {
            bottom: -100%;
            left: 0;
            width: 2px;
            height: 100%;
            background: linear-gradient(360deg, transparent, $color);
            animation: NeonButtonAnimate4 2s linear infinite;
            animation-delay: 1.50s;
        }
    }

    @keyframes NeonButtonAnimate1 {
        0% {
            left: -100%;
        }

        50%,
        100% {
            left: 100%;
        }
    }

    @keyframes NeonButtonAnimate2 {
        0% {
            top: -100%;
        }

        50%,
        100% {
            top: 100%;
        }
    }

    @keyframes NeonButtonAnimate3 {
        0% {
            right: -100%;
        }

        50%,
        100% {
            right: 100%;
        }
    }

    @keyframes NeonButtonAnimate4 {
        0% {
            bottom: -100%;
        }

        50%,
        100% {
            bottom: 100%;
        }
    }
}
